<script type="text/javascript">
import { cloudImageBaseUrl } from '@/CONF.js'
import { getCustomerList } from 'api/customerList'
import Clipboard from "clipboard";

export default {
    name: 'online',
    components: {
        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            cloudImageBaseUrl,
            list: [],
            header: {
                mainTitle: getWord('online_service'),
            },
        }
    },
    methods: {
        toNext(url) {
            window.location.href = url
        },
        getInfo() {
            getCustomerList(this.$route.query.type).then(result => {
                if (result.data.code == "SUCCESS") {
                    this.list = result.data.result;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        async copyText() {
            let clipboard = new Clipboard(".copy");
            clipboard.on("success", function(e) {
                if (clipboard) {
                    clipboard.destroy();
                }
            });
            app.Mint.Toast(getWord('clipboard_done'));
        },
    },
    mounted() {
        this.getInfo();
    },
}
</script>
<template>
    <section class="online" :class='_TEMPLATE'>
        <Header v-if="_TEMPLATE==='template-3'" :mainTitle="header.mainTitle" />
        <header v-if="_TEMPLATE==='template-1'  || _TEMPLATE==='template-2'">
            <mt-header :title="getWord('online_service')" style="height:0.88rem;font-size: 0.36rem;">
                <router-link to="/customer" slot="left">
                    <mt-button icon="back"></mt-button>
                </router-link>
            </mt-header>
        </header>
        <ul class="wrap">
            <li class="item" v-for='item in list' :key='item.account'>
                <div class="left">
                    <img :src="item.img" alt="" class="img">
                    <div class="txtBox">
                        <span>{{item.name}}</span>
                        <span>{{item.account}}</span>
                    </div>
                </div>
                <div class="right">
                    <button class="btn" @click='toNext(item.url)'>{{ getWord('online_service3') }}</button>
                </div>
            </li>
        </ul>
        <p class="tip" v-if="_TEMPLATE!=='template-3'">{{ getWord(['online_service3_tips','online_service4','interface']) }}</p>
    </section>
</template>
<style type="text/css" scoped lang='scss'>
.online {
    background: #f2f2f2;
    height: 100%;

    &.template-3 {

        ul {

            li {

                .left {

                    .txtBox {
                        font-weight: normal;
                    }
                }

                .right {

                    .btn {
                        background: transparent;
                        border: 1px solid #EC2829;
                        color: #EC2829;
                        font-size: .28rem;
                    }
                }
            }
        }
    }

    ul {
        padding: 3%;

        .item {
            display: flex;
            background: #fff;
            height: 1.32rem;
            border-radius: 5px;
            padding: 0 3%;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .left {
                display: flex;

                .img {
                    width: 1.08rem;
                    height: 1.08rem;
                    margin-right: 0.58rem;
                }

                .txtBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    font-size: 0.3rem;
                    font-weight: bold;
                }
            }

            .right {

                .btn {
                    font-size: 0.34rem;
                    color: #fff;
                    background: #4796fc;
                    outline: none;
                    border: 0;
                    height: 0.64rem;
                    border-radius: 3px;
                    line-height: 0.64rem;
                    padding: 0 5px;
                }
            }
        }
    }

    .tip {
        font-size: 0.32rem;
        text-align: center;
        color: #666;
    }
}
</style>